.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--black);
  font-weight: 500;
}

body {
  /* background-color: var(--white); */
  background-color: var(--dashboard-bg);
}

.logoMain {
  cursor: pointer;
}

.mainHeader {
  position: sticky;
  top: 0;
  background-color: var(--header-bg);
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  z-index: 99;
  height: 80px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-bottom: 3px solid var(--turquoise);
  transition: all 0.5s;
}

.thirdHeaderMain {
  background-color: var(--white);
  /* background-color: transparent; */
  position: sticky;
  top: 82px;
  z-index: 99;
  box-shadow: 0px 6px 12px #00000029;
  transition: all 0.5s;
}

.secondHeader {
  /* height: 100px; */
  background-color: var(--header-bg);
  border-top: 3px solid var(--turquoise);
  /* padding: 0px 100px; */
}

.insurance_box {
  cursor: pointer;
}

.policy-selected {
  background-color: #09143a !important;
}
.policy-selected img {
  filter: brightness(0) invert(1);
}
.policy-opacity {
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer;
}

.insurance_box div {
  padding: 20px;
  background-color: var(--white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 12px #00000015;
}

.insurance_combo_box {
  cursor: pointer;
  background-color: var(--white);
  border-radius: 12px;
  display: flex;
  padding: 25px;
  align-items: center;
  gap: 15px;
  position: relative;
}

.insurance_combo_box_main p {
  color: var(--header-bg);
  font-family: "jokker-semibold";
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

.insurance_combo_box_rec {
  padding: 8px;
  border-radius: 8px;
  background-color: var(--turquoise);
  color: var(--header-bg);
  font-family: "jokker-regular";
  font-size: 16px;
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
}

.insurance_box p {
  color: var(--header-bg);
  font-family: "jokker-semibold";
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px;
  text-align: center;
}

.secondHeaderItem {
  display: flex;
  align-items: center;
  gap: 20px;
}

.secondHeaderItem svg {
  font-size: 30px;
  fill: #fff;
}

.dash-border {
  border: 1px dashed var(--turquoise);
  padding: 5px;
  border-radius: 100px;
}

.bg-number {
  background-color: var(--turquoise);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  font-family: "jokker-semibold";
  color: var(--header-bg);
}

.secondHeader h4 {
  font-weight: bold;
  font-size: 28px;
  font-family: "bunch-bold";
  background-color: var(--header-bg);
}

/* .toolbar img {
    margin: 0 16px;
  } */

.headerLink {
  display: flex;
  gap: 20px;
  align-items: center;
}

.headerMenu {
  display: flex;
  justify-content: space-between;
  gap: 123px;
  align-items: center;
}

.responsive-header {
  display: none;
}

.headerLink {
  display: flex;
}

.headerLink li .selected-tab {
  color: var(--turquoise);
}

.headerLink a,
.headerLink .link {
  /* font-weight: 500;
  font-size: 16px;
  color: var(--white); */
  cursor: pointer;
  text-transform: capitalize;
  font-size: 18px;
  /* font-weight: 600; */
  color: var(--white);
  font-family: "jokker-medium";
}

.headerLink a:hover,
.headerLink .link:hover {
  color: var(--turquoise);
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--black);
}

/* .headerLink {
  margin-left: 80px;
} */

.loginBtn {
  /* margin-top: 10px; */
  /* margin-left: auto !important; */
  /* width: 125px;
  height: 44px; */
  /* border-radius: 8px; */
  /* border: none;
  color: var(--header-bg);
  font-family: "jokker-medium";
  background-color: var(--turquoise); */
  font-size: 18px;
  /* font-weight: 600; */
  cursor: pointer;
  transition: all 0.3s;
  /* box-shadow: 4px 4px 12px #ebebeb29 ; */
}
.loginBtn:hover{
  /* background-color: white; */

}
.positionbtn{
  position: absolute;
  bottom: 30px;
  right: 50px;
}
.menuDropLinksMain {
  transition: 0.5s;
  cursor: pointer;
}

.menuDropLinksMain:hover {
  color: var(--yellow);
}

.menuDrop {
  display: flex;
  gap: 10px;
  cursor: pointer;
  position: relative;
}

.menuDropMain {
  position: relative;
}

.menuDropLinks {
  position: absolute;
  top: 50px;
  border-top: 2px solid var(--yellow);
  width: 210px;
  background-color: var(--white);
  padding: 16px 10px;
  display: flex;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  gap: 35px;
}
.flex-direction-column{
  flex-direction: column;
}
.menuDropMain {
  display: flex;
}

.userInfo {
  cursor: pointer;
  margin-left: auto;
  display: flex;
  padding: 8px;
  gap: 8px;
  align-items: center;
  border: 1px solid var(--white);
  border-radius: 8px;
}

.profileUserName {
  /* cursor: pointer; */
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 400;
  color: var(--white);
  font-family: "jokker-medium";
}

/* .profileUserName:after {
  display: inline-block;
  margin: 0px 10px;
  padding-top: 5px;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  font-size: 20px;
  border-left: 0.3em solid transparent;
} */

.userImg {
  border-radius: 100px;
  background-color: var(--yellow);
  height: 48px;
  width: 48px;
}

.secondUserIcon {
  border-radius: 100px;
  background-color: var(--yellow);
  height: 60px;
  width: 60px;
  font-size: 24px;
  font-weight: 600;
  font-family: "jokker";
  color: var(--header-bg);
}

.thirdHeader {
  height: 79px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: var(--white);
  background-color: transparent;
  /* padding: 0px 100px; */
}
.css-1rud5z0-MuiBadge-badge {
  transform: scale(1) translate(25%, 0) !important;
}
.thirdHeader a,
.thirdHeader .notificationalert {
  color: var(--grey);
  font-size: 17px;
  font-family: "jokker-medium";
  letter-spacing: 0px;
  color: var(--header-bg);
  opacity: 1;
  padding: 15px;
  cursor: pointer;
}
.thirdHeader a:hover{
  background-color: var(--dashboard-bg);
  border-radius: 12px;
}
.thirdHeader span:hover{
  background-color: var(--dashboard-bg);
  border-radius: 12px;
}
.thirdHeader .active,.thirdHeader .active:hover,.activeNotification:hover{
  background-color: var(--header-bg) !important;
  border-radius: 12px;
  color: var(--white) !important;
}
.thirdHeader .active svg g path{
  fill: white !important;
}
.avatarUser {
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-family: "jokker-medium";
}

.userInfo h4 {
  margin: 0;
  padding: 0;
}

.mainSideBar {
  display: flex;
  flex-direction: column;
  width: 340px;
  height: 100%;
  padding: 30px;
  background-color: var(--header-bg);
}

.sideBarUser {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.sideBarUser .profileUserName {
  margin: 0;
  color: var(--white);
  font-family: "bunch-bold";
  font-size: 27px;
}

.sideBarUser .sideBarUserIcon {
  color: var(---header-bg);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  font-family: "Jokker-Regular";
  height: 60px;
  width: 60px;
  border-radius: 100px;
  background-color: var(--yellow);
  letter-spacing: 2px !important;
}

.sideBarTopMenu {
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid var(--sapphire-blue);
  padding-top: 29px;
  margin-top: 29px;
}

.sideBarTopMenu span {
  cursor: pointer;
  color: var(--white);
  font-size: 16px;
  font-family: "jokker-medium";
  padding: 21px 0px 21px 30px;
}

.sideBarTopMenu span:hover {
  color: var(--header-bg);
  background-color: var(--white);
  border-radius: 12px;
}

.sideBar-login {
  border-top: 0.5px solid var(--sapphire-blue);
  padding-top: 29px;
  margin-top: 29px;
  text-align: center;
}

.sideBarMenu {
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid var(--sapphire-blue);
  padding-top: 29px;
  margin-top: 29px;
}

.sideBarMenuList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sideBarMenu a {
  color: var(--white);
  font-size: 16px;
  font-family: "jokker-regular";
  padding: 21px 0px 21px 30px;
}

.sideBarMenu a:hover {
  color: var(--header-bg);
  background-color: var(--white);
  border-radius: 12px;
}

.sideBarBottomMenu {
  display: flex;
  flex-direction: column;
  /* border-top: 0.5px solid var(--sapphire-blue); */
  /* padding-top: 29px; */
  /* margin-top: 29px; */
}

.sideBarBottomMenu li {
  padding: 21px 0px 21px 30px;
  font-size: 16px;
  font-family: "jokker-regular";
  cursor: pointer;
}

.sideBarBottomMenu li:hover {
  color: var(--header-bg);
  background-color: var(--white);
  border-radius: 12px;
}

.sideBarBottomMenu li:hover span {
  color: var(--header-bg);
}

.sideBarBottomMenu li span {
  color: var(--white);
}

.notFoundContain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.notFoundContain p {
  color: var(--header-bg);
  text-align: center;
  font-size: 18px;
  font-family: "jokker-regular";
  margin: 40px 0px 20px 0px;
}

.exploreBtn {
  color: var(--header-bg);
  font-size: 18px;
  font-family: "jokker-regular";
  background: var(--yellow);
  border-radius: 8px;
  min-width: 129px;
  height: 53px;
  border: unset;
  transition: 0.5s;
  display: block;
  margin: auto;
}

.exploreBtn:hover {
  background-color: var(--header-bg);
  color: var(--white);
}

/* .mainSideBar li {
  padding: 10px;
} */
/* .mainSideBar .active {
  position: relative;
  background-color: var(--white);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  display: block;
} */
/* .mainSideBar .active::before {
  content: " ";
  height: 100%;
  width: 3px;
  background-color: var(--yellow);
  position: absolute;
  left: 0;
  top: 0;
} */
.sidebarToggal {
  display: none !important;
}

.sidebarHeader h4 {
  padding: 0;
  margin: 0;
}

.sidebarHeader h4 span {
  color: var(--yellow);
}

.sidebarHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid var(--light-gary);
}

.popupBg {
  width: 900px;
  height: 600px;
  background-color: var(--white);
  /* background-color: var(--dashboard-bg); */
  border-radius: 10px;
  margin: auto;
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  overflow: hidden;
}
.popupBg .illuuuu{
  width: 200px ;
}
.logImgMain {
  width: 50%;
  height: 100%;
  background-color: var(--header-bg);
  position: relative;
}

.closeLoginModalButton {
  position: absolute !important;
  top: 10px;
  right: 10px;
}

.popupContain {
  z-index: 2;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 70px;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}

.roundShap {
  height: 450px;
  width: 450px;
  background-color: var(--off-gary);
  border-radius: 100%;
  position: absolute;
  left: -70px;
  top: -70px;
  z-index: 0;
}

.formContainMain {
  width: 62%;
  /* padding: 45px; */
  color: var(--header-bg);
  height: max-content;
  overflow-y: auto ;
}
.formContainMain .overflow{
  padding: 45px; 
}
.tabView .MuiBox-root {
  margin-top: 50px;
  padding: 0;
}

.activity-tabs {
  font-family: "jokker-regular";
}

.activity-tabs .css-1a7hnq3-MuiButtonBase-root-MuiTab-root.Mui-selected {
  font-family: "jokker-bold";
}

.error_block {
  display: flex;
  justify-content: center;
  margin: 0px;
  color: red;
  font-weight: 600;
}

.otp-section .error_block {
  margin-top: 20px;
}

.please_wait_block {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiDrawer-root .MuiPaper-root {
  overflow-x: hidden !important;
}
.otpButton,
.otp-button {
  cursor: pointer;
}

.otpButton {
  width: 100%;
}

.otp-button {
  margin-top: 30px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.otp-button[disabled] {
  margin-top: 30px;
  background-color: var(--yellow);
  cursor: not-allowed;
}

.otpButton[disabled] {
  background-color: var(--yellow);
  cursor: not-allowed;
}

.account-link {
  cursor: pointer;
  text-align: center;
  font-family: "jokker-light";
}

.account-link a {
  color: var(--header-bg) !important;
  font-family: "jokker-bold";
}

.OtpFild {
  border: 1px solid var(--pearl);
  width: 48px;
  height: 48px;
  border-radius: 4px;
  text-align: center;
}

.OtpFildMain {
  margin-top: 40px;
}

.resend {
  color: var(--off-black);
  font-size: 12px;
  text-align: center;
  margin-top: 30px !important;
  margin-bottom: 20px !important;
}

.resend-vault {
  color: var(--white);
  font-size: 14px;
  font-family: "jokker-medium";
  text-align: center;
  margin: 0;
}

.d-flex {
  display: flex;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.gap-17 {
  gap: 17px;
}

.stap {
  height: 10px;
  width: 10px;
  background-color: var(--ivory);
  border-radius: 10px;
}

.stap-main {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 40px;
}

.stap-active {
  width: 20px;
  background-color: var(--black-secondary);
}

.userEdit {
  position: relative;
}

.menu-img {
  width: 35px;
}

.edit-pen {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 18px;
  width: 18px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 10px;
  border-radius: 100px;
  border: 1px solid var(--black) 000;
}

/* loader css */
.loaderArea {
  background-color: var(--black);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
}

.loaderArea::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  animation: ripple 1s ease-in-out infinite forwards;
  border-radius: 50%;
}

.loaderArea::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  animation: ripple 1s 1.3s ease-in-out infinite forwards;
  border-radius: 50%;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(6);
    opacity: 0;
  }
}

/*.loader {*/
/*  height: 20vh;*/
/*  width: 20vh;*/
/*  max-height: 480px;*/
/*  min-width: 480px;*/
/*}*/
.loaderBg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.73);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loader-contain {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(9, 20, 58, 0.3);
  /* background-color: rgba(255, 255, 255, 0.73); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

/*.loaderTransform {*/
/*  opacity: 0.8;*/
/*}*/
/* End loader css */
.option_card {
  width: 100%;
  background-color: var(--header-bg);
  text-align: center;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 6px 12px #00000008;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  cursor: pointer;
  width: auto;
  transition: 0.5s;
}

.option_card:hover {
  transform: translateY(-10px);
}

.disabled-card {
  background-color: var(--off-white) !important;
}

.option_img {
  height: 50px;
  width: 50px;
  /* background-color: #f5f5f5; */
  margin: auto;
}

.option_card_header p {
  color: var(--white);
  font-size: 18px;
  font-family: "jokker-bold";
  margin: 0;
  width: calc(100% - 30px);
}

.option_card_header input {
  height: 24px;
  width: 24px;
  color: var(--white);
}

.survey_card_header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.survey_card_header label {
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-family: "jokker-medium";
}

.survey_card_header input {
  height: 24px;
  width: 24px;
  color: var(--white);
}

.loan_ref_box {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
}

.sticky_footer {
  padding: 16px 0;
  background-color: var(--white);
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 99;
}

.profile_fildes_main p {
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 16px;
  color: var(--header-bg);
  font-family: "jokker-regular";
}

.profile_main_div {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 40px;
}

.profile_fildes {
  width: -webkit-fill-available;
  width: -moz-available;
  padding: 20px;
  border: unset;
  box-shadow: 0px 6px 12px #00000008;
  border-radius: 5px;
  font-size: 16px;
  color: var(--header-bg);
  font-family: "jokker-regular";
}

.insurance_option_radio {
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid var(--white);
  background-color: var(--header-bg);
  border-radius: 50%;
  accent-color: var(--turquoise);
  cursor: pointer;
}

.insurance_option_radio:checked {
  box-shadow: 0 0 0 2px var(--white);
  background-color: var(--turquoise);
  border: 2px solid var(--header-bg);
  /* border-width: 0.2rem; */
}

.survey_option_radio {
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid var(--header-bg);
  background-color: var(--white);
  border-radius: 50%;
  accent-color: var(--turquoise);
  cursor: pointer;
}

.survey_option_radio:checked {
  box-shadow: 0 0 0 2px var(--white) !important;
  background-color: var(--turquoise) !important;
  border: 2px solid var(--header-bg) !important;
  /* border-width: 0.2rem; */
}

.profile_fildes:focus {
  outline: unset;
}

.notFoundContain img {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.sidebarToggal {
  color: var(--white) !important;
  position: absolute !important;
  right: 30px;
  top: 0;
  padding: 0 !important;
}

.header-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 5px;
  background-color: var(--turquoise) !important;
  z-index: 999;
}

.teams-contain {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
  border-top: 2px solid var(--turquoise);
  padding-top: 40px;
}

.view_button[disabled] {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.activeNotification {
  background-color: var(--header-bg);
  border-radius: 12px;
  color: var(--white) !important;
}
.notificationalert .MuiBadge-badge{
  color: var(--white) !important;
  top: 15px;
  right: 15px;
  /* background-color: var(--yellow) !important; */
}
.notificationalert .MuiBadge-badge:hover{
  background-color:  #FF0000 !important;
  color: var(--white) !important;
}
hr {
  opacity: 0.5;
}
/* .quote-list-model{
  position: absolute;
  height: 100%;
  right: 0;
} */
.breadcrumb-main {
  padding: 9px 0 !important;
}

@media only screen and (max-width: 1299px) {

  .hero_text p {
    font-size: 16px;
    line-height: 24px;
  }

  .join_section h1 {
    margin-bottom: 50px !important;
  }
  .values_card div {
    max-width: 264px;
  }
  .values_card:nth-child(2) div {
    margin: 0 45px;
  }

  .how_work_section_dark_box h4,
  .values_card h2,
  .overview_card_heading h2,
  .main_modal_bg #modal-modal-title {
    font-size: 22px;
  }
  .how_work_section_dark_box p {
    font-size: 14px;
    line-height: 20px;
  }
  .overview_card_number h1 {
    font-size: 54px;
  }
  .css-5aijib {
    padding: 30px !important;
  }
  .how_work_section_box_main h3 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 25px !important;
  }
  .banner-explore-btn {
    width: 160px;
    height: 46px;
    font-size: 16px;
  }
  .overview_table_text {
    font-size: 16px !important;
  }

  .offer_card h3 {
    font-size: 28px;
    line-height: 34px;
  }
  .offer_card p,
  .values_card p,
  .image_col_bg p,
  .image_col_bg_step_text,
  .insurance_box p {
    font-size: 14px;
    line-height: 20px;
  }

  .Insurance_Marketplace_card_new {
    padding: 25px;
  }
  .thirdHeader a, .loginBtn{
    font-size: 18px;
  }
 
  .thirdHeader .notificationalert,
  .profile-title,
  .notification_contain h3,
  .notification_contain p,
  .notification_right {
    font-size: 16px;
  }
  .thirdHeader {
    height: 70px;
  }
  .layout .pt-40 h1 {
    font-size: 28px;
  }
  .convertAgentContain {
    gap: 25px;
  }
  .notification_contain h3 {
    margin-bottom: 6px;
  }
  .profile-input-section {
    margin-top: 16px;
    border-radius: 16px;
  }
  .notification_left img {
    height: 35px;
    width: 35px;
  }
  .notification_card {
    padding: 25px 30px;
  }
  .thirdHeader a,
  .thirdHeader .notificationalert {
    padding: 11px;
    font-size: 15px !important;
  }
  .css-1rud5z0-MuiBadge-badge {
    transform: scale(1) translate(50%, 0) !important;
  }
  .image_col_bg h1 {
    font-size: 26px;
    line-height: 32px;
  }
  .insurance_flex_box,
  .main_modal {
    gap: 30px;
  }
  .insurance_box div {
    padding: 18px;
  }
  .insurance_box img,
  .icon-bg-active-card img {
    height: 40px;
  }
  .overview_title {
    font-size: 22px;
  }
  .quote-request {
    height: 46px;
  }
  .active-lead-btn button {
    font-size: 16px !important;
  }
  .sideBarTopMenu span,
  .sideBarBottomMenu li {
    padding: 16px !important;
  }
  .verification-status,
  .tag {
    font-size: 14px !important;
  }
  .popupBg .illuuuu {
    width: 210px;
}
  .profileUserName {
    font-size: 16px !important;
  }
  .userInfo {
    gap: 10px;
    padding: 10px;
  }
  .overview_insurance_card h3,
  .overview_insurance_card_policy_inner p {
    font-size: 16px;
  }
  .overview_insurance_card_policy_inner_text_bold,
  .overview_insurance_card_download {
    font-size: 16px !important;
  }
  .overview_insurance_card_download {
    height: 40px;
  }

  .modal_header {
    padding: 16px !important;
  }
  .notFoundContain img {
    width: 73%;
    margin: 20px auto;
  }
  .notFoundContain p {
    font-size: 16px;
    margin-top: 0;
  }
  .coverages_text {
    font-size: 18px;
  }
  .main_modal_bg #modal-modal-title.mainPopup {
    font-size: 16px !important;
  }
  .resend {
    font-size: 12px;
  }
}



@media only screen and (max-width: 1199.98px) {
  .offer_card p {
    min-height: 86px;
  }
}
@media only screen and (max-width: 1023.98px) {
  .logImgMain {
    display: none;
  }

  .createAccount {
    width: 90% !important;
    max-height: 90vh;
    overflow: auto;
  }

  .model-contain-main-responsive {
    padding: 20px !important;
  }

  .modal_header_coverages {
    padding: 10px;
  }

  .modal_header {
    padding: 10px;
  }

  /* .createAccount .form-section {
    width: 100% !important;
  } */
  .createAccount .form-section.w-50 {
    width: 100% !important;
  }

  .formContainMain {
    min-width: 350px;
    padding: 20px;
    width: 100%;
    max-width: 300px;
  }

  .popupBg {
    width: fit-content;
    height: auto;
    max-height: 96vh;
    overflow: auto;
  }

  .createAccount .image-section {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .loginBtnHeader {
    display: none;
  }

  .responsiveBtnHeader {
    display: block !important;
  }

  .toolbar {
    justify-content: space-between;
  }

  .userInfo h4 {
    display: none;
  }

  .userImg {
    height: 30px;
    width: 30px;
  }

  .logoMain:not(.verify-model .logoMain) {
    width: 90px;
  }

  .mainHeader {
    height: 60px;
  }

  .preview-main-header-section {
    top: 63px !important;
  }

  /* .popupBg {
    width: 700px;
  }
  .createAccount {
    width: 700px !important;
  } */

  .roundShap {
    height: 300px;
    width: 300px;
  }

  .thirdHeader {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .popupBg {
    width: fit-content;
  }

  .model-contain-main-responsive-upload {
    max-height: 80vh !important;
  }

  .model-contain-main-responsive .model-contain {
    max-height: 200px;
    overflow: auto;
  }

  .model-contain-main-upload .model-contain-main-responsive .model-contain {
    max-height: unset !important;
    overflow: unset !important;
  }

  .popupBg {
    height: auto;
  }

  .createAccount {
    height: auto !important;
  }

  .OtpFild {
    width: 40px;
    height: 40px;
  }

  .otpVerification {
    min-width: unset;
    width: 100%;
  }

  .popupBg {
    max-height: 95vh;
    overflow: auto;
  }
}

@media only screen and (max-height: 600px) {
  .model-contain-main-responsive-upload {
    max-height: 50vh;
  }
}
@media only screen and (max-height: 500px) {
  .model-contain-main-responsive .model-contain {
    max-height: 140px !important;
    overflow: auto;
  }

  .model-contain-main-upload .model-contain-main-responsive .model-contain {
    max-height: unset !important;
    overflow: unset !important;
  }

  .model-contain-res {
    max-height: 140px !important;
    overflow: auto;
  }

  .model-contain-main-responsive-upload {
    max-height: 50vh;
  }
}

@media only screen and (max-height: 500px) {
  .res-create-modal-title {
    margin-top: 43px;
  }
}

@media only screen and (max-height: 400px) {
  .res-create-modal-title {
    margin-top: 175px;
  }
  .res-modal-title {
    /* width: 70%; */
    margin-top: 66px;
  }
}
@media only screen and (max-width: 375px) {
  .formContainMain {
    width: 100%;
    padding: 10px;
    min-width: unset;
    max-width: 300px;
  }

  .resend {
    margin-top: 40px !important;
  }

  .OtpFild {
    width: 30px;
    height: 30px;
  }
}
